<template>
    <div  class="adr-unallocated-disputes-contaner">
      <div class="adr-unallocated-disputes-header">
        UNASSIGNED CASES
      </div>
      <div class="adr-unallocated-dispute-items">

        <template v-for="dispute in unallocatedDisputes" :key="dispute.id">
          <ADRUnAllocatedDispute :dispute="dispute" @click="activeDispute = dispute" :active="activeDispute?.id === dispute.id" />
        </template>

      </div>  

      <div v-if="hasDisputeAndFacilitator" class="assign-dispute-facilitator">
        <p>Assign {{facilitatorDescription}} <span>{{facilitator.name}}</span> to <span>{{disputeWord}}:</span> <span>{{activeDispute.reference}}</span> for <span>{{formatActiveDate}}</span>?</p> 
      </div>
      <div v-if="hasDisputeAndFacilitator" class="btn-primary assign-facilitator-to-dispute-button" @click="assignFacilitatorToDispute">
        ASSIGN {{facilitatorDescription}}
      </div>
      
    </div>
</template>


<script>

const DISPUTE_DESCRIPTIONS = {
  dispute:'DISPUTE',
  trial_referral:'TRIAL',
  application_referral:'APPLICATION'
};

import ADRUnAllocatedDispute from  '@/views/ADRUnAllocatedDispute.vue';

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";

export default {
  components: {ADRUnAllocatedDispute},
  props: {  
    date:{type:Date,required:true},
    facilitatorUserId:{type:Number,default:0}
  },
  data() {
    return {
      activeDispute:null
    }
  },
  created() {

  },
  mounted() {

  },
  computed:{
    ...mapGetters(['facilitatorUserIdField','disputes','facilitators','facilitatorDescription']),
  	...mapState([]),
    disputeWord() {
      if (this.hasDisputeAndFacilitator) {
        console.log('activeDispute:',this.activeDispute);
        let disputeType =  this.activeDispute?.dispute_type || 'dispute';
        return DISPUTE_DESCRIPTIONS[disputeType] || 'DISPUTE'
      }
      else 
      {
        return 'DISPUTE'
      }
    },
    formatActiveDate() {
      let currentDate = this.date;
      let options = { year: 'numeric', month: 'long', day: 'numeric' };
      let formatter = new Intl.DateTimeFormat('en-ZA', options);
      return formatter.format(currentDate);
    },
    ymd() {
      let currentDate = this.date;
      let options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      let formatter = new Intl.DateTimeFormat('en-ZA', options);
      return formatter.format(currentDate);
    },
    hasDisputeAndFacilitator() {
      return ( (this.activeDispute !== null) && (this.activeDispute?.id > 0) && (this.facilitatorUserId > 0));
    },
    facilitator() {
      let res = {name:'N/A',facilitator_user_id:0};
      if (this.facilitatorUserId !== 0 ) {
        return this.facilitators[this.facilitatorUserId];
      }
      return res;
    },
    unallocatedDisputes() {
      let res = {};
      for (let d in this.disputes) {
        let dispute = this.disputes[d];
        console.log('Dispute:',dispute,this.facilitatorUserIdField,dispute[this.facilitatorUserIdField]);
        if ((dispute[this.facilitatorUserIdField] === 0) && (dispute.statuses.dispute_status === 'active')) {
          res[d] = dispute;
        }
      }
      return res;
    }
  },
  methods:{
  	...mapActions(['assignFacilitator']),
  	...mapMutations([]),
    onDisputeClick(disputeId) {
      this.activeDisputeId = disputeId 
    },
    assignFacilitatorToDispute() {
      let disputeId = this.activeDispute.id;
      let facilitatorUserId = this.facilitatorUserId;
      let date = this.ymd;
      this.assignFacilitator({dispute_id:disputeId,facilitatorUserId:facilitatorUserId,date:date})
    }
  }
 }

</script>

<style>

div.adr-unallocated-disputes-contaner{
  
}

div.assign-dispute-facilitator{
  text-align: center;
}

div.adr-unallocated-disputes-contaner > div > p > span{
  font-weight: bold;
  white-space: nowrap;
}

div.assign-facilitator-to-dispute-button{
  display:flex;
  align-items:center;
  justify-content: center;
  border: 1px solid green;
  padding:10px;

    background-image: linear-gradient(var(--theme-color-light), var(--theme-color) 60%, var(--theme-color-dark));
    color: white;
    border-radius: 2px;
    border: 1px solid var(--theme-color);
    font-weight:bold;
    cursor:pointer;

      text-transform: uppercase;

      margin:10px;
      margin-left:10px;
      margin-right:10px;
      border-radius: 5px;
      /* max-width: 200px; */
}


div.adr-unallocated-disputes-header{
  min-height: 50px;
  font-size: 25px;
  display:flex;
  align-items:center;
  justify-content: center;
  font-weight: bold;
  color:var(--theme-color);
}

div.assign-dispute-facilitator{
  background-color:var(--theme-color-off-white);
  color:var(--theme-color-dark);
  margin:10px;
  border-radius: 10px;
  box-shadow: 5px 5px 5px rgba(100,100,100,0.5);
  padding:10px;
}

</style>


